<template>
  <div id="register-page">
    <v-flex xs12>
      <v-layout wrap class="register">
        <v-flex xs11 sm11 md11 offset-sm1 offset-md1 v-if="!isMobile">
          <span class="text ml-9"> {{ $t('register.subtitle')}} </span>
        </v-flex>
        <v-flex xs11 sm11 md11 offset-sm1 offset-md1>
          <v-form
            ref="form"
            class="mt-9 ml-9"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <v-flex xs12 sm4 md4>
                <v-text-field
                  v-model="email"
                  ref="email"
                  label="Email"
                  :placeholder="$t('register.email')"
                  :rules="emailRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-text-field
                  v-model="password"
                  :type="seePassword1 ? 'text' :'password'"
                  ref="password"
                  :label="$t('register.password')"
                  :rules="passRules"
                  :outlined="isMobile"
                  :append-icon="seePassword1 ? 'mdi-eye-off' :'mdi-eye'"
                  @click:append="seePassword1 = !seePassword1"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-text-field
                  v-model="password2"
                  :type="seePassword2 ? 'text' :'password'"
                  ref="password2"
                  :label="$t('register.retype-password')"
                  :rules="pass2Rules"
                  :outlined="isMobile"
                  :append-icon="seePassword2 ? 'mdi-eye-off' :'mdi-eye'"
                  @click:append="seePassword2 = !seePassword2"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-text-field
                  v-model="name"
                  ref="name"
                  :label="$t('register.name')"
                  :rules="nameRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-combobox
                  v-model="gender"
                  :items="['MALE', 'FEMALE']"
                  :label="$t('register.gender')"
                  :outlined="isMobile"
                  :rules="genderRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="birthDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDate"
                      :outlined="isMobile"
                      :label="$t('register.birth-date')"
                      append-icon="mdi-calendar"
                      :rules="birthDateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="birthDate"
                    no-title
                    scrollable
                    @input="$refs.menu.save(birthDate)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-combobox
                  v-model="profesion"
                  :items="jobOptions"
                  item-text="name"
                  item-value="id"
                  :label="$t('register.profesion')"
                  :outlined="isMobile"
                  multiple
                  :rules="jobRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm9 md9>
                <v-textarea
                  v-model="address"
                  ref="address"
                  :label="$t('register.address')"
                  :rules="addressRules"
                  :outlined="isMobile"
                  :rows="isMobile ? '5' : '1'"
                  required
                ></v-textarea>
                <span class="address-note"> {{ $t('register.address-note') }}</span>
              </v-flex>
              <v-flex xs12 sm4 md4 :class="isMobile ? 'mt-5' : ''">
                <v-combobox
                  v-model="province"
                  :items="provinceList"
                  :label="$t('register.province')"
                  :outlined="isMobile"
                  :rules="provinceRules"
                  @input="getCity()"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-combobox
                  v-model="city"
                  :items="cityList"
                  :label="$t('register.city')"
                  :outlined="isMobile"
                  :rules="cityRules"
                  @input="getDistrict()"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-combobox
                  v-model="district"
                  :items="districtList"
                  item-text="subdistrict"
                  item-value="destination_code"
                  :label="$t('register.district')"
                  :outlined="isMobile"
                  :rules="districtRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-text-field
                  v-model="zip_code"
                  ref="zip_code"
                  :label="$t('register.zip-code')"
                  :rules="zipCodeRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-text-field
                  v-model="instagram"
                  ref="instagram"
                  label="Instagram"
                  :outlined="isMobile"
                  :rules="igRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 offset-sm1 offset-md1>
                <v-text-field
                  v-model="phone"
                  ref="phone"
                  :label="$t('register.phone')"
                  :rules="phoneRules"
                  :outlined="isMobile"
                  prefix="+62"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm9 md9>
                <v-checkbox
                  v-model="statement"
                  :rules="statementRules"
                  :label="$t('register.subtitle2')"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm4 md4 class="mb-9">
              <v-btn class="mt-5" tile :color="bgColor" dark width="100%" height="44px" @click="register()">
                <span class="btn-text"> {{ $t('register.title')}} </span>
              </v-btn>
            </v-flex>
          </v-form>
         </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <Otp :user_id="user_id" @verified="success()" @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import Otp from '@/components/Otp'

  export default {
    name: 'Register',
    components: { Otp },
    computed: {
      ...mapState([
        'isMobile',
        'bgColor'
      ])
    },
    data () {
      return {
        menu: false,
        dialog: '',
        user_id: '',
        email: '',
        password: '',
        password2: '',
        name:'',
        gender: '',
        birthDate: '',
        profesion: '',
        family_name: '',
        address: '',
        province: '',
        city: '',
        district: '',
        zip_code: '',
        phone: '',
        instagram: '',
        statement: '', 
        valid: true,
        jobOptions: [
          { id: 'KARYAWAN', name: 'Karyawan'},
          { id: 'PELAJAR', name: 'Pelajar / Mahasiswa'},
          { id: 'WIRAUSAHA', name: 'Wiraswasta / Wirausaha'}
        ],
        jobRules: [
          v => !!v || this.$t('register.form.error-profesion'),
          v => v && v.length > 0 || this.$t('register.form.error-profesion')
        ],
        emailRules: [
          v => !!v || this.$t('register.form.error-email'),
          v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.trim()) || this.$t('register.form.error-valid-email')
        ],
        passRules: [
          v => !!v || this.$t('register.form.error-password'),
        ],
        pass2Rules: [
          v => !!v || this.$t('register.form.error-retype-password'),
          v => v === this.password || this.$t('register.form.error-password-not-match')
        ],
        nameRules: [
          v => !!v || this.$t('register.form.error-name'),
          v => (v && v.length >= 3) || this.$t('register.form.error-name-min-length'),
          v => (v && v.length <= 25) || this.$t('register.form.error-name-max-length'),
        ],
        genderRules: [
          v => !!v || this.$t('register.form.error-gender'),
        ],
        igRules: [
          v => !!v || this.$t('register.form.error-ig'),
        ],
        birthDateRules: [
          v => !!v || this.$t('register.form.error-birthdate'),
          v => this.meetsMinimumAge(v,18) || this.$t('register.form.error-birthdate-min')
        ],
        addressRules: [
          v => !!v || this.$t('register.form.error-address'),
        ],
        provinceRules: [
          v => !!v || this.$t('register.form.error-province'),
        ],
        cityRules: [
          v => !!v || this.$t('register.form.error-city'),
        ],
        districtRules: [
          v => !!v || this.$t('register.form.error-district'),
        ],
        zipCodeRules: [
          v => !!v || this.$t('register.form.error-zip-code'), 
        ],
        phoneRules: [
          v => !!v || this.$t('register.form.error-phone'),
          v => /^8\d{7,15}$/.test(v) || this.$t('register.form.error-phone-format'),
        ],
        statementRules: [
          v => !!v || this.$t('register.form.error-statement'),
        ],
        seePassword1: false,
        seePassword2: false,
        provinceList: [],
        cityList: [],
        districtList: [],
      }
    },
    methods: {
      meetsMinimumAge(birthDate, minAge) {
          let date = new Date(birthDate)
          let tempDate = new Date(date.getFullYear() + minAge, date.getMonth(), date.getDate())
          return (tempDate <= new Date())
      },
      setMinimumAge () {
        let tempDate = new Date()
        this.birthDate = this.formatDate2(new Date(tempDate.getFullYear() - 18, tempDate.getMonth(),tempDate.getDate()))
      },
      needOtp () {
        this.dialog = true
      },
      success () {
        this.dialog = false
        this.$router.push({ name: 'Login'})
      },
      getProvince () {
        axios.get('/api/v1/master/province')
        .then(response => {
          this.provinceList = response.data.data.map(item => {
            return item.province
          })
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      getCity () {
        this.city = ''
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          province: this.province,
          is_offline: true
        }

        axios.get('/api/v1/master/city')
        .then(response => {
          this.cityList = response.data.data.map(item => {
            return item.city
          })
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      getDistrict () {
        this.district = ''
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          city: this.city,
          is_offline: true
        }

        axios.get('/api/v1/master/subdistrict')
        .then(response => {
          this.districtList = response.data.data
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      register () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          let data = {
            email: this.email.trim(),
            password: this.password,
            confirm_password: this.password2,
            nama_pelanggan: this.name.trim() + ' ' + this.family_name.trim(),
            gender: this.gender,
            tanggal_lahir: this.birthDate,
            jenis_pekerjaan: this.profesion.map(item => item.id),
            hp: '0' + this.phone,
            instagram: this.instagram,
            kode_pos: this.zip_code,
            destination_code : this.district.destination_code,
            alamat_lengkap : this.address,
            source: 'STORE'
          }

          axios.post('/api/v1/member/register', data)
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)

              if (response.data && response.data.code === 'VER001') {
                this.user_id = response.data.data.id_pelanggan
                this.needOtp()
              } else {
                this.$router.push({ name: 'Login'})
              }
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } 
      }
    },
    mounted () {
      this.setMetaInfo(null, this.$route.name)
      this.getProvince()
      this.setMinimumAge()
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', true)
      this.$store.commit('setActivePage', this.$t('login-register'))
    }
  }
</script>

<style scoped>
  .text {
    font-size: 1.7rem;
    color: #000000;
    font-weight: 600;
  }

  .text-2 {
    font-size: 18px;;
    color: #000000;
    font-weight: 600;
  }

  .p-text {
    margin-top: 15px;
    font-size: 14px;;
    color: #000000;
  }

  .btn-text {
    font-weight: 500;
  }

  .register {
    color: #FFFFFF;
    position: relative;
    margin: auto;
    margin-top: 10%;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }

  .address-note {
    color:#000000;
    font-size: 11px;
    text-align: justify;
  }

</style>
